<template>
  <b-form @submit.prevent="onSubmit">
    <b-row>
      <b-col md="12" v-if="createFlow">
        <b-form-group>
          <b-form-checkbox
            v-model="form.sso"
            id="sso"
            name="sso"
            size="lg"
            switch>
            SSO User
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col md="12" v-if="isAccLocked">
        <b-form-group>
          <b-form-checkbox
            v-model="form.locked"
            id="locked"
            name="locked"
            switch
            size="lg"
            class="account-lock"
          >
            <label for="locked" class="pl-5 pt-3" v-if="form.locked">Account Locked</label>
            <label for="locked" class="pl-5 pt-3" v-else>Account Unlocked (Save Required)</label>
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group>
          <label for="first_name">First name</label>
          <b-form-input
            size="lg"
            id="first_name"
            name="first_name"
            v-model="$v.form.first_name.$model"
            :state="validateState('first_name')"
            aria-describedby="first_name-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="first_name-live-feedback">
            Please enter a first name.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <label for="last_name">Last name</label>
          <b-form-input
            size="lg"
            id="last_name"
            name="last_name"
            v-model="$v.form.last_name.$model"
            :state="validateState('last_name')"
            aria-describedby="last_name-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="last_name-live-feedback">
            Please enter a last name.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <label for="email">Email address</label>
          <b-form-input
            size="lg"
            id="email"
            name="email"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="email-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="email-live-feedback">
            {{ emailErrorMessage }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <label for="username">Username <small>(email preferred)</small></label>
          <b-form-input
            size="lg"
            id="username"
            name="username"
            v-model="$v.form.username.$model"
            @focus="usernameActive()"
            :state="validateState('username')"
            aria-describedby="username-live-feedback"
          >
          </b-form-input>
        </b-form-group>

      </b-col>
      <b-col md="6">
        <b-form-group>
          <label for="employee_id">Employee ID</label>
          <b-form-input
            size="lg"
            id="employee_id"
            name="employee_id"
            v-model="form.employee_id"
            aria-describedby="employee_id-live-feedback"
          >
          </b-form-input>
        </b-form-group>

        <b-form-group>
          <label for="supervisor_id">Manager ID</label>
          <b-form-input
            size="lg"
            id="supervisor_id"
            name="supervisor_id"
            v-model="form.supervisor_id"
            aria-describedby="supervisor_id-live-feedback"
          >
          </b-form-input>
        </b-form-group>

        <b-form-group v-if="customerGroup && locationOptions.length">
          <label for="location">Location</label>
          <b-form-select
            size="lg"
            id="location"
            name="location"
            v-model="$v.form.location.$model"
            :options="locationOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Role"
          label-size="lg"
          label-class="font-weight-bold pt-0"
        >
          <b-form-select
            size="lg"
            id="role"
            name="role"
            v-model="$v.form.role.$model"
            :options="roleOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <SplitListSelect
          v-if="displayAccountAdminOption"
          label="Assign Client Accounts"
          :options="assignableAccounts"
          v-model="form.managing_accounts"
          valueKey="id"
          filterLabel="Filter Clients"
          :searchFilter="filterClients"
          selectedLabel="Assigned Clients"
          notSelectedLabel="Available Clients"
          nothingSelectedMessage="HBC Account Admin will only have access to these client accounts."
        >
          <template v-slot:default="slotProps">
            {{ slotProps.option.name }}
          </template>
        </SplitListSelect>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Languages"
          label-size="lg"
          label-class="font-weight-bold pt-0"
        >
          <b-form-checkbox-group
            id="languages"
            v-model="$v.form.languages.$model"
            :options="languageOptions"
            name="languages"
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-row>
      <b-col>
        <b-form-group
          label="Sender Profiles"
          v-if="customerGroup && senderProfileOptions.length"
          label-size="lg"
          label-class="font-weight-bold pt-0"
        >
          <b-form-checkbox-group
            id="senderProfiles"
            v-model="$v.form.sender_profiles.$model"
            :options="senderProfileOptions"
            name="senderProfiles"
          ></b-form-checkbox-group>
        </b-form-group>

        <SplitListSelect
          v-if="displayReportingUsersOption"
          label="Reporting users"
          :options="assignableUsers"
          v-model="form.reporting_users"
          valueKey="email"
          filterLabel="Filter Users"
          :searchFilter="filterUsers"
          selectedLabel="Reporting Users"
          notSelectedLabel="Users"
          nothingSelectedMessage="All users within this client will currently display in this Manager's report"
        >
          <template v-slot:default="slotProps">
            {{ getNameString(slotProps.option) }}
            <small class="d-block">{{ slotProps.option.email }}</small>
          </template>
        </SplitListSelect>

        <b-form-group class="mb-0">
          <b-button variant="primary" size="lg" type="submit" block class="mt-3">Save</b-button>
        </b-form-group>
      </b-col>
    </b-form-row>

  </b-form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapState, mapActions } from 'vuex';
import SplitListSelect from '@/components/splitListSelect.vue';

export default {
  name: 'EditUserForm',
  props: [
    'user',
    'errors',
    'customerGroup',
    'isSSO',
    'createFlow'
  ],
  components: {
    SplitListSelect
  },
  mixins: [validationMixin],
  data() {
    return {
      filterQuery: '',
      languageOptions: [
        {
          value: 'en_US',
          text: 'English'
        },
        {
          value: 'es',
          text: 'Español'
        },
      ],
      form: {
        employee_id: '',
        supervisor_id: '',
        first_name: '',
        last_name: '',
        email: '',
        username: '',
        location: '',
        languages: [],
        sender_profiles: [],
        reporting_users: [],
        managing_accounts: [],
        role: '',
        enabled: false,
        sso: false,
        locked: false,
      }
    }
  },
  validations() {
    const validations = {
      form: {
        first_name: {
          required,
        },
        last_name: {
          required,
        },
        email: {
          required,
        },
        username: {
          required,
        },
        languages: {
          required
        },
        role: {
          required,
        },
      }
    };
    if (this.customerGroup && this.locationOptions.length) {
      validations.form.location = {
        required,
      };
    }
    if (this.customerGroup && this.senderProfileOptions.length) {
      validations.form.sender_profiles = {
        required
      };
    }
    return validations;
  },
  computed: {
    ...mapGetters('ocapi', [
      'getSenderProfiles',
      'getClientConfig',
      'allCustomerGroups',
    ]),
    ...mapGetters('ping', [
      'parsedIdToken',
      'manageableUsers'
    ]),
    ...mapGetters('admin', [
      'getLocations'
    ]),
    ...mapState('admin', [
      'roles',
    ]),
    roleOptions() {
      let roles = this.roles;
      if (this.parsedIdToken.profile.role !== 'admin') {
        roles = this.roles.filter(role => (role.value !== 'admin' && role.value !== 'accountadmin'));
      }
      return roles.map(role => {
        return {
          value: role.value,
          text: role.label
        }
      })
    },
    clientConfig() {
      return this.getClientConfig(this.customerGroup);
    },
    senderProfiles() {
      return this.getSenderProfiles(this.customerGroup);
    },
    locationOptions() {
      return this.getLocations(this.clientConfig).map(location => {
        return {
          value: location.value,
          text: location.value,
          default: location?.default
        }
      })
    },
    emailErrorMessage() {
      if (this.emailInUse) {
        return 'An account for this email address already exists';
      }
      return 'Please enter a valid email address.';
    },
    senderProfileOptions() {
      return this.senderProfiles.map(profile => {
        return {
          value: profile.profileID,
          text: profile.profileName
        }
      })
    },
    emailInUse() {
      return this.errors.filter(error => {
        return (error.code === 'UNIQUENESS_VIOLATION' && error.target === 'username') || (error.code === 'UNIQUENESS_VIOLATION' && error.target === 'email');
      }).length > 0;
    },
    assignableUsers() {
      return this.manageableUsers.filter(user => !this.user || user.email !== this.user.email)
    },
    assignableAccounts() {
      return this.allCustomerGroups.filter(group => group.id !== 'All')
    },
    displayReportingUsersOption() {
      return this.form.role === 'reporting'
    },
    displayAccountAdminOption() {
      return this.form.role === 'accountadmin'
    },
    isAccLocked() {
      return this.user?.locked?.status
    }
  },
  methods: {
    ...mapActions('ping', [
      'fetchIDAUsers',
    ]),
    getNameString(user) {
      let name = '';
      if (user?.name?.family) {
        name += user.name.family;
      }
      if (user?.name?.given) {
        name += `, ${user.name.given}`;
      }
      return name;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      if (name === 'email' && this.emailInUse) {
        return false;
      }
      return $dirty ? !$error : null;
    },
    usernameActive(event) {
      const username = 'username';
      const email = 'email';
      if (this.$v.form[username].$model.length === 0) {
        this.$v.form[username].$model = this.$v.form[email].$model
      }
    },
    filterUsers(options, query) {
      if (query === '') {
        return options;
      }
      return options.filter(option => {
        const regex = new RegExp(`(${query})`, 'i');
        return option?.name?.family?.match(regex) || option?.name?.given?.match(regex) || option?.email?.match(regex);
      })
    },
    filterClients(options, query) {
      if (query === '') {
        return options;
      }
      return options.filter(option => {
        const regex = new RegExp(`(${query})`, 'i');
        return option?.id?.match(regex) || option?.name?.match(regex);
      })
    },
    initializeForm() {
      console.log(this);
      const form = {
        employee_id: this.user?.profile?.employeeID || '',
        supervisor_id: this.user?.profile?.supervisorID || '',
        first_name: this.user?.name?.given || '',
        last_name: this.user?.name?.family || '',
        email: this.user?.email || '',
        username: this.user?.username || '',
        location: this.user?.profile?.location,
        languages: this.user?.salesforceUserData?.languages || (this.user?.email ? [] : ['en_US']),
        sender_profiles: this.user?.salesforceUserData?.sender_profiles || this.senderProfiles.filter(profile => profile?.newUserDefault || false).map(profile => profile.profileID),
        reporting_users: this.user?.profile?.reportingUsers || [],
        role: this.user?.profile?.role || 'orderer',
        managing_accounts: this.user?.profile?.managingAccounts || [],
        sso: this.clientConfig?.defaultNewUserTypeSSO || false,
        locked: this.user?.locked?.status || false,
      };
      if (!form.location) {
        const defaultLocation = this.locationOptions.find(location => location.default);
        form.location = defaultLocation?.value || this.locationOptions[0]?.value;
      }
      console.log(form);
      this.form = form;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        console.log(this.$v.form);
        return;
      }
      let locked = null;
      if (this.user?.locked?.status) {
        locked = { status: true };
        if (!this.form.locked) {
          locked = {
            status: false,
            unlockedOn: new Date()
          }
        }
      }
      this.$emit('submit', {
        name: {
          given: this.form.first_name,
          family: this.form.last_name
        },
        email: this.form.email.toLowerCase().trim(),
        username: this.form.username.toLowerCase().trim(),
        salesforceUserData: {
          favorites: this?.user?.salesforceUserData?.favorites || [],
          languages: this.form.languages,
          sender_profiles: this.form.sender_profiles,
        },
        profile: {
          employeeID: this.form.employee_id,
          supervisorID: this.form.supervisor_id,
          location: this.form.location,
          role: this.form.role,
          managingAccounts: this.form.managing_accounts,
          reportingUsers: this.form.reporting_users
        },
        locked: locked,
      }, this.form.sso);
    }
  },
  watch: {
    user() {
      this.initializeForm();
    },
    customerGroup() {
      this.initializeForm();
      this.fetchIDAUsers()
        .then(console.log)
        .catch(console.log);
    },
  },
  mounted() {
    this.initializeForm();
    this.fetchIDAUsers()
      .then(console.log)
      .catch(console.log);
  }
}
</script>

<style lang="scss">
.account-lock.custom-switch {
  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      color: #fff;
      border-color: #FF0000;
      background-color: #FF0000;
      content: "\f023";
      font-family: fontAwesome;
      font-size: 26px;
      height: 2.5rem;
      width: 5rem;
      padding-left: 0.5rem;
    }
    &::after {
      height: 2rem;
      width: 1.5rem;
      margin-left: 2rem;
      background-color: #adb5bd;
    }
  }
  .custom-control-input ~ .custom-control-label {
    &::before {
      color: #fff;
      border-color: #00FF00;
      background-color: #00FF00;
      content: "\f09c";
      font-family: fontAwesome;
      font-size: 26px;
      height: 2.5rem;
      width: 5rem;
      padding-left: 2.5rem;
    }
    &::after {
      height: 2rem;
      width: 1.5rem;
      margin: 2px;
      background-color: #adb5bd;
    }
  }
}
</style>
